import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {FormControl, Input, InputAdornment, InputLabel, Slider} from "@mui/material";
import {MathJax, MathJaxContext} from "better-react-mathjax";

const renderHz = (n: number) => {
    if (n < 1e3) {
        return `${n}Hz`;
    }
    if (n < 1e6) {
        return `${(n/1e3).toFixed(2)}kHz`;
    }
    if (n < 1e9) {
        return `${(n/1e6).toFixed(2)}MHz`;
    }
    return `${(n/1e9).toFixed(2)}GHz`;
}

const renderBps = (n: number) => {
    if (n < 1e3) {
        return `${n}bit/s`;
    }
    if (n < 1e6) {
        return `${(n/1e3).toFixed(2)}kbit/s`;
    }
    if (n < 1e9) {
        return `${(n/1e6).toFixed(2)}Mbit/s`;
    }
    return `${(n/1e9).toFixed(2)}Gbit/s`;
}

const renderDia = (n: number) => {
    return `${n}mm`
}

function InputSlider(props: { min: number, settingCallback: (value: (((prevState: number) => number) | number)) => void, renderCallback: (value: number) => string, max: number, name: string, target: number }) {

    const [sliderId, setSliderId] = useState("");
    const [step, setStep] = useState(1);

    useEffect(() => {
        setSliderId(props.name.toLowerCase().replaceAll("[^a-z]", "_"));
    }, [props.name]);

    useEffect(() => {
        setStep(((props.max) < 100) ? 0.1 : 1 );
    }, [props.max]);

    return (
        <>
            <h2 style={{fontSize: "small"}}><InputLabel htmlFor={sliderId}>{props.name}</InputLabel></h2>
            <div style={{padding: 10}}>
                <Slider aria-label={sliderId} id={sliderId} step={step}
                        value={props.target}
                        min={props.min} max={props.max} onChange={(e, v) => props.settingCallback(Array.isArray(v) ? v[0] : v)}/>
                <div id={`${props.name.toLowerCase()}_val`}>{props.renderCallback(props.target)}</div>
            </div>
        </>
    )
}

function App() {
    const nf = new Intl.NumberFormat('en-US');
    const [worldGDP] = useState(96100091004540.90);
    const [cost, setCost] = useState(2.0);
    const [bandwidth, setBandwidth] = useState(1e3);
    const [sampleRate, setSampleRate] = useState(1e3);
    const [lineDia, setLineDia] = useState(1);
    const [delaySec, setDelaySec] = useState(30);
    const [temp, setTemp] = useState(25);
    const [density, setDensity] = useState(13533);
    const [tension, setTension] = useState(0.4842);
    const [velocity, setVelocity] = useState(10);
    const [length, setLength] = useState(1);
    const [volume, setVolume] = useState(1);
    const [clock, setClock] = useState(1e6);
    const [totalCost, setTotalCost] = useState(1);
    const [cmPerTick, setCmPerTick] = useState(1);
    const [gdp, setGdp] = useState(0);
    const [gdpText, setGdpText] = useState("");

    useEffect(() => {
        setGdp((totalCost/worldGDP) * 100);
    }, [totalCost, worldGDP]);

    useEffect(() => {
        setGdpText((gdp < 0.1) ? "<0.1" : gdp.toFixed(2));
    }, [gdp])

    useEffect(() => {
        setCmPerTick((velocity*100)/clock);
    }, [velocity, clock]);

    useEffect(() => {
        setDensity(13595 - ((13595-13472) / 50) * temp);
        const diff = ((484.2-479) / 25) * (temp - 25);
        setTension((484.2 - diff) / 1e3);
    }, [temp])

    useEffect(() => {
        const bottom = (6.33e-10) * (density);
        setVelocity(Math.pow((tension / bottom), (2/3)));
    }, [density, tension]);

    useEffect(() => {
        setTotalCost(volume*cost);
    }, [volume, cost]);

    useEffect(() => {
        setVolume(Math.PI * ((lineDia/2)*(lineDia/2)) * (length/100));
    }, [length, lineDia]);

    useEffect(() => {
        setLength( (4 * sampleRate * bandwidth * delaySec) * cmPerTick);
    }, [velocity, bandwidth, delaySec, sampleRate, cmPerTick]);

    return (
        <MathJaxContext>
        <div className="App" style={{
            padding: 20
        }}>
            <h1 style={{fontSize: "medium"}}>Mercury delay line calculatatron v1.0</h1>
            <h2 style={{fontSize: "medium"}}>("how much mercury does Taff need to buy")</h2>

            <InputSlider min={0.1} max={5} name={"Cost per KG Hg"} target={cost} settingCallback={setCost} renderCallback={(n: number) => {return "$" + n.toFixed(2)}}/>
            <InputSlider min={1e3} max={5e7} name={"Incoming I/Q bandwidth"} target={bandwidth} settingCallback={setBandwidth} renderCallback={renderHz}/>
            <InputSlider min={1e3} max={1e7} name={"Sample Rate"} target={sampleRate} settingCallback={setSampleRate} renderCallback={renderHz}/>
            <InputSlider min={1} max={120} name={"Required Delay (sec)"} target={delaySec} settingCallback={setDelaySec} renderCallback={(n: number) => {return n + "sec"}}/>
            <h3>Delay line properties</h3>
            <InputSlider min={0.1} max={10} name={"Line Diameter (mm)"} target={lineDia} settingCallback={setLineDia} renderCallback={renderDia}/>
            <InputSlider min={1e3} max={2e6} name={"Clock (Hz)"} target={clock} settingCallback={setClock} renderCallback={renderHz}/>
            <h3>Environment</h3>
            <InputSlider min={0} max={40} name={"Server room temperature"} target={temp} settingCallback={setTemp} renderCallback={(n: number) => {return n + "°C"}}/>
            <small>(fuck off with your °F)</small>
            <h3>Density Hg (ρ)</h3>
            <div><a href={"https://www.engineeringtoolbox.com/mercury-d_1002.html"}>Estimated</a> as: {density.toFixed(1)}kg/m<sup>3</sup></div>
            <h3>Surface tension</h3>
            <div><a href={"https://doi.org/10.1039/TF9464200526"}>Roughly</a>: {(tension * 1e3).toFixed(1)} dynes/cm</div>
            <h3>Velocity through delay line</h3>
            <div>Using Auerbach's equation:</div>
            <MathJax>{"\\[ \n" +
                "u = \\left(  \\frac{ \\sigma } { 6.33 \\times 10^{-10}  \\rho }  \\right)^{ \\frac{2}{3} }\n" +
                "\\]"}</MathJax>
            <MathJax dynamic={true}>{"\\[ \n" +
                " = \\left(  \\frac{ " + tension.toFixed(3) + " } { 6.33 \\times 10^{-10}  \\cdot " + density.toFixed(1) + " }  \\right)^{ \\frac{2}{3} }\n" +
                "\\]"}</MathJax>
            <MathJax dynamic={true}>{"\\[ \n" +
                " = " + velocity.toFixed(2) + " m^{-1}\n" +
                "\\]"}</MathJax>
            <h3>Total required line length</h3>
            <div>{(length/100).toExponential(1)} m ({((length/100000).toFixed(2))} km) {Boolean(length > 1e12) && (
                <>
                    ({(length*1.0570008340247e-18).toFixed(4)} light years)
                </>
            )}</div>
            <small>[for reference, delay line on UNIVAC I, 384bit/5ft = 2.52bits/cm@40°C]</small>
            <h3>Hg volume (as a function of line Ø) and cost</h3>
            <div>{volume.toFixed(2)}L &mdash; <strong>${nf.format(totalCost)}</strong>&nbsp;
                <>
                    ({ gdpText }% of planet Earth's GDP)
                </>

            </div>
        </div>
        </MathJaxContext>
    );
}

export default App;
